import { DatabaseOutlined, HomeOutlined, LogoutOutlined, SettingOutlined } from "@ant-design/icons";
import { Logout } from "@redux/actions/Auth/Auth";
import { MenuProps } from "antd";
import { Link } from "react-router-dom";
import { ROUTE_PATHS } from "./constants";
import { AppDispatch } from "@redux/reducers/rootReducer";
import { useDispatch } from "react-redux";

type MenuItem = Required<MenuProps>["items"][number];
function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type
  } as MenuItem;
}

export const useGetMenuItems = () => {
  const dispatch = useDispatch<AppDispatch>();
  const logOut = () => {
    dispatch(Logout());
  };
  const items: MenuItem[] = [
    getItem(<Link to={ROUTE_PATHS.ADMIN}>Home</Link>, "1", <HomeOutlined />),
    getItem(
      <Link to={ROUTE_PATHS.ADMIN_VIEW_USER_LOGS}>User Logs</Link>,
      "2",
      <DatabaseOutlined />
    ),
    getItem(<Link to={ROUTE_PATHS.ADMIN_SETTINGS}>Settings</Link>, "3", <SettingOutlined />)
  ];
  const logoutItem: MenuItem[] = [
    getItem(
      <Link to={ROUTE_PATHS.AUTH_LOGIN} onClick={logOut}>
        Logout
      </Link>,
      "4",
      <LogoutOutlined />
    )
  ];
  return [items, logoutItem];
};
