import { FC, ReactNode, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { ADMIN } from "@utils/constants";
import { IAppState } from "@redux/reducers/rootReducer";
import { IAuthState } from "@redux/reducers/Auth/Auth";
import { Flex, Layout, Menu } from "antd";
import { ROUTE_PATHS } from "./constants";
import AppLayout from "@Component/AppLayout";
import { useAntToken } from "@hooks/common/useAntToken";
import { useBreakpoints } from "@hooks/common/useBreakPoints";
import { LogoIcon, MobileLogoIcon } from "@assets/icons/CustomIcons";
import { useGetMenuItems } from "./useGetMenuItems";
import { menuStyles } from "./styles";
const { Content, Sider } = Layout;

interface Props {
  children?: ReactNode;
}

export const AdminRoute: FC<Props> = ({ children }) => {
  const location = useLocation();
  const { innerWidth: width } = window;
  const { token } = useAntToken();
  const { isScreenXL } = useBreakpoints();
  const loginState: IAuthState = useSelector((state: IAppState) => state.auth);
  const { role } = loginState;
  const { styles } = menuStyles();

  const [collapsed, setCollapsed] = useState(false);
  const [items, logoutItem] = useGetMenuItems();
  if (!loginState.token) {
    return <Navigate to={ROUTE_PATHS.AUTH_LOGIN} />;
  }
  if (role !== ADMIN) {
    return <Navigate to={ROUTE_PATHS.UNAUTHORIZED} />;
  }

  return (
    <AppLayout style={{ minHeight: "100vh" }}>
      <Sider
        collapsible={!isScreenXL}
        breakpoint="xl"
        collapsedWidth="60"
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        theme="light"
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          zIndex: 2,
          left: 0,
          top: 0,
          bottom: 0,
          paddingLeft: collapsed ? token.paddingXXS : token.paddingSM,
          paddingRight: collapsed ? token.paddingXXS : token.paddingSM
        }}
        className={styles["ant-sider-override"]}
        width={212}>
        {isScreenXL || !collapsed ? (
          <Flex align="center" justify="center" style={{ height: "137px" }}>
            <LogoIcon
              style={{
                fontSize: 130,
                width: "100%",
                height: "60px",
                justifyContent: "center"
              }}
            />
          </Flex>
        ) : (
          <Flex align="center" justify="center" style={{ height: "96px" }}>
            <MobileLogoIcon style={{ fontSize: 50, width: "28px", height: "50px" }} />
          </Flex>
        )}
        <Flex justify="space-between" vertical style={{ height: "75%" }}>
          <Menu
            theme="light"
            style={{ background: token.colorBgBase, color: token.colorText, borderRight: 0 }}
            defaultSelectedKeys={["1"]}
            mode="inline"
            items={items}
            className={styles["ant-menu-override"]}
          />
          <Menu
            theme="light"
            style={{
              background: token.colorBgBase,
              color: token.colorText,
              borderRight: 0,
              marginBottom: !collapsed && !isScreenXL ? 48 : 0
            }}
            defaultSelectedKeys={["1"]}
            mode="inline"
            items={logoutItem}
            className={styles["ant-menu-override"]}
          />
        </Flex>
      </Sider>
      <Layout>
        <Content>
          <Flex>
            <div style={{ width: isScreenXL ? 212 : 60 }}></div>
            <div
              style={{
                width: "100%",
                paddingLeft: width <= 375 && location.pathname.includes("/admin/invoice") ? 60 : 0
              }}>
              {children}
            </div>
          </Flex>
        </Content>
      </Layout>
    </AppLayout>
  );
};
