import { createStyles } from "antd-style";

export const commonAntStyles = createStyles(({ token: _token, css }) => ({
  "ant-input-override": {
    "input[type='text'].ant-input:focus": css`
      outline-style: none;
      box-shadow: none;
      border-color: transparent;
    `,
    "input[type='text'].ant-input:-webkit-autofill": css`
      -webkit-box-shadow: 0 0 0px 1000px white inset;
    `,
    ".ant-input-number:focus-within": css`
      box-shadow: none;
    `,
    ".ant-input-number": css`
      max-width: 100%;
    `,
    "#password, #confirmPassword": css`
      outline-style: none;
      box-shadow: none;
      border-color: transparent;
      background-color: transparent;
      -webkit-box-shadow: 0 0 0px 1000px white inset;
    `,
    "#password, #confirmPassword:-webkit-autofill": css`
      -webkit-box-shadow: 0 0 0px 1000px white inset;
    `,
    "input[type='date'].ant-picker:focus": css`
      outline-style: none;
      box-shadow: none;
      border-color: transparent;
      -webkit-box-shadow: 0 0 0px 1000px white inset;
    `,

    "textarea:focus": css`
      outline-style: none;
      box-shadow: none;
      border-color: transparent;
      -webkit-box-shadow: 0 0 0px 1000px white inset;
    `
  },
  "tooltip-inner": css`
    white-space: pre-line;
  `
}));
