import { FC, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { isAxiosError } from "axios";
import dayjs from "dayjs";
import { Col, Input, Row, Typography, DatePicker, Form, Tooltip, Drawer } from "antd";
import CommonAppButton from "@Component/Common/CommonAppButton";
import CommonFormLabel from "@Component/Common/CommonFormLabel";
import RedirectToOrignalInvoiceButton from "@Component/Common/RedirectToOrignalInvoiceButton";
import { CheckCircleOutlined, CloseCircleOutlined, EyeOutlined } from "@ant-design/icons";
import { useAntToken } from "@hooks/common/useAntToken";
import { useBreakpoints } from "@hooks/common/useBreakPoints";
import {
  INVOICE_APPROVED,
  INVOICE_PENDING,
  INVOICE_REJECTED,
  TInvoiceApprovalStatus
} from "@utils/constants";
import { commonAntStyles } from "@Component/Common/styles";
import AdminCancelAndGoBack from "@Component/Common/AdminCancelAndGoBack";
import { invoiceCancel } from "@Services/InvoiceService";
import ShowToast from "@utils/showToast";

interface IInvoiceSideContentProps {
  invoiceId: string;
  invoiceInfo: {
    companyName: string;
    propertyName: string;
    address: string;
    invoiceNumber: string;
    issueDate: string | null;
  };
  onIssuingCompanyInfoChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onIssueDateChange?: (date: any, dateString: any) => void;
  handleSubmit?: () => void;
  loading: boolean;
  originalInvoiceUrl: string;
  readonlyView?: boolean;
  propertyManagerView?: boolean;
  handleInvoiceApprovalStatusChange?: (status: TInvoiceApprovalStatus) => void;
  isInvoiceApprovalAllowed?: boolean;
  handleInvoiceRejection?: (rejectionReason?: string, isLienWaiverRequired?: boolean) => void;
  invoiceApprovalStatus?: TInvoiceApprovalStatus;
  invoiceRejectionReason?: string;
  isRegionalManager?: boolean;
  requestLienWavier?: boolean;
  legalFormUrl?: string;
  displayCancelButton?: boolean;
}

const InvoiceSideContent: FC<IInvoiceSideContentProps> = (props) => {
  const {
    invoiceId,
    invoiceInfo,
    onIssuingCompanyInfoChange,
    onIssueDateChange,
    handleSubmit,
    loading,
    originalInvoiceUrl,
    readonlyView,
    propertyManagerView,
    isInvoiceApprovalAllowed,
    handleInvoiceRejection,
    invoiceApprovalStatus,
    invoiceRejectionReason,
    handleInvoiceApprovalStatusChange,
    requestLienWavier,
    legalFormUrl,
    displayCancelButton
  } = props;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { isScreenXS } = useBreakpoints();
  const { token } = useAntToken();
  const { styles: commonStyles } = commonAntStyles();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleCancelInvoice = async (invoiceId: string) => {
    try {
      await invoiceCancel(invoiceId);
      navigate("/");
      ShowToast("Invoice Cancelled successfully", "success");
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ShowToast(error.response?.data.message, "error");
      }
    }
  };

  return (
    <Col
      style={{
        padding: !isScreenXS ? token.paddingXL : token.paddingXS,
        paddingTop: !isScreenXS ? token.paddingMD : token.paddingXS,
        margin: "auto",
        marginTop: 10
      }}>
      <Col>
        <Typography.Title
          style={{
            fontWeight: token.fontWeightStrong,
            fontSize: token.fontSizeHeading4,
            marginTop: 0
          }}>
          Basic Info
        </Typography.Title>
      </Col>
      <Col>
        <Form layout="vertical">
          {invoiceId && (
            <Form.Item
              label={<CommonFormLabel>Invoice Id</CommonFormLabel>}
              className={commonStyles["ant-input-override"]}>
              <Input
                disabled={true}
                style={{
                  borderRadius: token.borderRadiusXS,
                  backgroundColor: "transparent",
                  borderColor: token.colorBorderSecondary
                }}
                value={invoiceId}
                name="invoiceId"
              />
            </Form.Item>
          )}
          <Form.Item
            label={<CommonFormLabel>Invoice Number</CommonFormLabel>}
            className={commonStyles["ant-input-override"]}>
            <Input
              disabled={readonlyView || propertyManagerView}
              style={{
                borderRadius: token.borderRadiusXS,
                backgroundColor: "transparent",
                borderColor: token.colorBorderSecondary
              }}
              placeholder="Invoice Number"
              defaultValue={invoiceInfo.invoiceNumber}
              value={invoiceInfo.invoiceNumber}
              name="invoiceNumber"
              onChange={onIssuingCompanyInfoChange}
            />
          </Form.Item>
          <Form.Item
            label={<CommonFormLabel>Invoice Date</CommonFormLabel>}
            className={commonStyles["ant-input-override"]}>
            <DatePicker
              className={commonStyles["ant-input-override"]}
              disabled={readonlyView || propertyManagerView}
              style={{
                padding: "0 10px",
                borderRadius: token.borderRadiusXS,
                backgroundColor: "transparent",
                width: "100%",
                height: token.controlHeight,
                borderColor: token.colorBorderSecondary
              }}
              value={invoiceInfo.issueDate ? dayjs(invoiceInfo.issueDate as string) : null}
              onChange={onIssueDateChange}
            />
          </Form.Item>
        </Form>
      </Col>
      <Row>
        <Row
          justify="center"
          style={{
            marginTop: token.marginLG,
            marginBottom: token.marginLG,
            width: "100%"
          }}>
          <Col span={24}>
            {!readonlyView && !propertyManagerView && (
              <CommonAppButton
                type="primary"
                block
                style={{
                  fontSize: token.fontSizeLG,
                  height: token.controlHeightLG
                }}
                onClick={handleSubmit}
                loading={loading}>
                Send Invoice
              </CommonAppButton>
            )}

            {legalFormUrl && (
              <Link to={legalFormUrl} target="_blank">
                <CommonAppButton
                  type="primary"
                  block
                  style={{
                    height: token.controlHeightLG,
                    color: token.colorTextBase,
                    fontSize: token.fontSizeLG,
                    fontWeight: 500,
                    borderRadius: 0,
                    marginTop: token.marginLG,
                    background: "transparent",
                    boxShadow: token.boxShadow,
                    borderColor: token.colorBorder
                  }}
                  icon={<EyeOutlined />}
                  loading={loading}>
                  View Lien Waiver Form
                </CommonAppButton>
              </Link>
            )}

            <CommonAppButton
              type="primary"
              block
              style={{
                height: token.controlHeightLG,
                color: token.colorTextBase,
                fontSize: token.fontSizeLG,
                fontWeight: 500,
                borderRadius: 0,
                marginTop: token.marginLG,
                background: "transparent",
                boxShadow: token.boxShadow,
                borderColor: token.colorBorder
              }}
              icon={<EyeOutlined />}
              onClick={showDrawer}
              loading={loading}>
              View Original Invoice
            </CommonAppButton>

            <RedirectToOrignalInvoiceButton
              linkText="View Original Invoice in New Tab"
              redirectionUrl={originalInvoiceUrl}
              style={{
                color: token.colorTextBase,
                borderColor: token.colorBorder,
                height: token.controlHeightLG
              }}
              icon={<EyeOutlined />}
            />
            {displayCancelButton && invoiceApprovalStatus === INVOICE_PENDING && (
              <CommonAppButton
                type="primary"
                block
                style={{
                  marginTop: token.marginLG,
                  fontSize: token.fontSizeLG,
                  height: token.controlHeightLG,
                  background: "transparent",
                  boxShadow: token.boxShadow,
                  color: token.colorTextBase,
                  borderColor: token.colorBorder
                }}
                icon={<CloseCircleOutlined />}
                onClick={() => handleCancelInvoice(invoiceId)}
                loading={loading}>
                Cancel Invoice
              </CommonAppButton>
            )}
            {propertyManagerView &&
              invoiceApprovalStatus !== INVOICE_APPROVED &&
              invoiceApprovalStatus !== INVOICE_REJECTED && (
                <Row gutter={[8, 8]} style={{ marginTop: token.marginLG }}>
                  <Col span={24}>
                    <CommonAppButton
                      block
                      icon={<CloseCircleOutlined />}
                      style={{
                        fontSize: token.fontSizeLG,
                        height: token.controlHeightLG,
                        color: token.colorBgBase,
                        borderColor: token.colorErrorActive,
                        backgroundColor: token.colorError,
                        boxShadow: token.boxShadow
                      }}
                      onClick={() => handleInvoiceRejection?.()}>
                      Reject
                    </CommonAppButton>
                  </Col>
                  <Col span={24} style={{ marginTop: token.marginSM }}>
                    <Tooltip
                      color={token.colorError}
                      title={
                        !isInvoiceApprovalAllowed
                          ? "Can't Approve invoice with rejected line items"
                          : "blah blah"
                      }>
                      <CommonAppButton
                        disabled={!isInvoiceApprovalAllowed}
                        block
                        icon={<CheckCircleOutlined />}
                        onClick={() => handleInvoiceApprovalStatusChange?.(INVOICE_APPROVED)}
                        style={{
                          fontSize: token.fontSizeLG,
                          height: token.controlHeightLG,
                          color: token.colorBgBase,
                          borderColor: token.colorSuccessActive,
                          backgroundColor: token.colorSuccess,
                          boxShadow: token.boxShadow,
                          opacity: isInvoiceApprovalAllowed ? 1 : 0.5
                        }}>
                        Approve
                      </CommonAppButton>
                    </Tooltip>
                  </Col>
                  {requestLienWavier && (
                    <Col span={24}>
                      <CommonAppButton
                        type="primary"
                        block
                        style={{
                          marginTop: token.marginSM,
                          fontSize: token.fontSizeLG,
                          height: token.controlHeightLG,
                          background: "transparent",
                          boxShadow: token.boxShadow,
                          color: token.colorTextBase,
                          borderColor: token.colorWarningBorder
                        }}
                        icon={<CloseCircleOutlined />}
                        onClick={() =>
                          handleInvoiceRejection?.("Lien Waiver form is Required", true)
                        }
                        loading={loading}>
                        Request Lien Waiver Form
                      </CommonAppButton>
                    </Col>
                  )}
                </Row>
              )}
            <AdminCancelAndGoBack
              marginTop={12}
              title={"Go Back"}
              buttonStyle={{ marginTop: token.marginSM }}
            />
            {invoiceApprovalStatus === INVOICE_REJECTED && invoiceRejectionReason && (
              <Col
                span={24}
                style={{
                  border: `1px solid ${token.colorError}`,
                  padding: token.paddingSM,
                  marginTop: token.marginLG
                }}>
                <Typography.Paragraph
                  strong
                  style={{ color: token.colorError, textDecoration: "underline" }}>
                  Rejection Reason for Invoice:{" "}
                </Typography.Paragraph>
                <Typography.Paragraph>{invoiceRejectionReason}</Typography.Paragraph>
              </Col>
            )}
          </Col>
        </Row>
      </Row>
      <Drawer
        title="Original Invoice"
        onClose={onClose}
        open={open}
        width={isScreenXS ? "100%" : "50%"}>
        <Row style={{ height: "100%", width: "100%" }}>
          <iframe src={originalInvoiceUrl} width={"100%"} height={"100%"} />
        </Row>
      </Drawer>
    </Col>
  );
};

export default InvoiceSideContent;
