import { FC } from "react";
import { useNavigate } from "react-router-dom";
import AdminDashboard from "@Component/Admin/AdminDashboard";
import { ROUTE_PATHS } from "@routes/constants";
import { TUserType } from "@Component/Admin/types";

const AdminDashboardDashboardPage: FC = () => {
  const navigate = useNavigate();

  const navigateToInviteVendor = () => {
    navigate(ROUTE_PATHS.ADMIN_INVITE_VENDOR);
  };

  const navigateToInviteBusinessUser = () => {
    navigate(ROUTE_PATHS.ADMIN_INVITE_BUSINESS_USER);
  };
  const navigateToViewUsers = (userType: TUserType) => {
    const role = userType === "vendors" ? "vendor" : "user";
    navigate(`${ROUTE_PATHS.ADMIN}/${userType}?role=${role}`);
  };

  return (
    <AdminDashboard
      navigateToInviteVendor={navigateToInviteVendor}
      navigateToInviteBusinessUser={navigateToInviteBusinessUser}
      navigateToViewUsers={navigateToViewUsers}
    />
  );
};
export default AdminDashboardDashboardPage;
