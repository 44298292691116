import { FC, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { DatePickerProps } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import {
  fetchInvoiceById,
  fetchVendorInvoiceGlCodes,
  invoiceUpdate
} from "@Services/InvoiceService";
import ShowToast from "@utils/showToast";
import { INVOICE_PENDING, INVOICE_REJECTED } from "@utils/constants";
import { IAuthState } from "@redux/reducers/Auth/Auth";
import { IAppState } from "@redux/reducers/rootReducer";
import { isAxiosError } from "axios";
import useFetchOriginalInvoiceUrl from "@hooks/common/useFetchOriginalInvoiceUrl";
import ReviewInvoice from "@Component/Common/ReviewInvoice";
import { getInvoiceTotal } from "../VendorReviewInvoicePage/utils";
import { shortIdGenerator } from "@utils/shortIdGenerator";
import { getUpdatedLineItemsOnEditing, transformInvoiceForSubmission } from "./utils";
import SignDocumentModal from "@Component/Common/ReviewInvoice/SignDocumentModal";
import useSignDocumentState from "@Component/Vendor/Common/useSignDocumentState";
import {
  ILineItem,
  IInvoice,
  IInvoiceSerialized,
  InvoiceProps,
  TGlCode,
  TProperties,
  TUnit,
  TAlternativeGlCode
} from "@Component/Common/types";
import LineItemChangeModal from "@Component/Vendor/VendorViewRejectedInvoice/LineItemChangeModal";

const VendorViewRejectedInvoicePage: FC = () => {
  const { invoiceId } = useParams();
  const navigate = useNavigate();
  // eslint-disable-next-line
  const formRef = useRef<any>();
  const [invoiceInfo, setInvoiceInfo] = useState<InvoiceProps>({
    invoiceId: "",
    companyName: "",
    propertyName: "",
    propertyAddress: "",
    propertyType: "",
    address: "",
    approvalStatus: "",
    invoiceNumber: "",
    issueDate: "",
    totalAmount: 0
  });
  const loginState: IAuthState = useSelector((state: IAppState) => state.auth);
  const [displayIssuingCompanyForm, setDisplayIssuingCompanyForm] = useState<boolean>(false);
  const [displayIssueDateInput, setDisplayIssueDateInput] = useState<boolean>(false);
  const [lineItems, setLineItems] = useState<ILineItem[]>([]);
  const [lastIndex, setLastIndex] = useState<number>(0);
  const [changedValues, setChangedValues] = useState<any>([]);
  const [changedValueModalOpen, setChangedValueModalOpen] = useState<boolean>(false);
  const [checkboxSigned, setCheckboxSigned] = useState<boolean>(false);
  const [displayAddItemModal, setDisplayAddItemModal] = useState<boolean>(false);
  const [glCodes, setGlCodes] = useState<TGlCode[]>([]);
  const [invoiceTotal, setInvoiceTotal] = useState<number>(0);
  const [property, setProperty] = useState<TProperties>();
  const [loading, setLoading] = useState<boolean>(false);
  const [invoice, setInvoice] = useState<IInvoice>();
  const [totalTaxAmount, setTotalTaxAmount] = useState<number>(0);
  const [selectedRowTaxable, setSelectedRowTaxable] = useState<boolean>(false);
  const [units, setUnits] = useState<TUnit[]>([]);
  const {
    defaultValues,
    updateDefaultValues,
    openSignDocumentModal,
    handleSignDocumentModalClose,
    handleSignDocumentModalOpen,
    isFormSigned,
    submissionId,
    handleFormSignSuccess
  } = useSignDocumentState();
  const { loginInfo } = loginState;

  const editGLCodes: boolean = loginInfo?.editGLCodes ? loginInfo?.editGLCodes : false;
  const threshold = loginInfo?.threshold;
  const vendorName = loginInfo?.name;
  const vendorEmail = loginInfo?.email;

  const fetchInvoice = async () => {
    setLoading(true);
    const [invoiceResponse, glCodesResponse] = await Promise.all([
      fetchInvoiceById(invoiceId),
      fetchVendorInvoiceGlCodes()
    ]);
    setGlCodes(glCodesResponse.data.glCodes);
    const invoice: IInvoice = invoiceResponse?.data;
    setProperty(invoice.property);
    setInvoice(invoice);

    if (invoice?.property?.unit) {
      const unitsArray = invoice.property.unit.filter((unit) => unit.id != 0);
      invoice.property.unit = [{ id: 0, name: "None" }, ...unitsArray];
      setUnits(invoice.property.unit);
    }
    let lastIndex = 0;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    setInvoiceInfo({
      companyName: invoice?.payee.name,
      propertyName: invoice?.property.name,
      propertyAddress: invoice?.propertyAddresses[0]?.Address || "",
      propertyType: invoice?.propertyType,
      address: invoice?.payee.address,
      approvalStatus: invoice?.approvalStatus,
      vendorName,
      invoiceNumber: invoice.invoiceId,
      issueDate: invoice.issueDate ? moment(invoice.issueDate).format("YYYY-MM-DD") : null,
      persistedInvoiceFileId: invoice?.persistedInvoiceFileId
    });

    const lineItems: ILineItem[] = invoice.lineItems.map((l, index: number) => {
      lastIndex = index;

      return {
        lineId: l.lineId,
        key: index,
        glCode: l.glCode as TGlCode,
        description: l.description,
        pricePerUnit: l.preTaxAmount as number,
        approvalStatus: l.approvalStatus,
        rejectionReason: l.rejectionReason,
        amount: l.amount as number,
        postTaxAmount: l.amount as number,
        preTaxAmount: l.preTaxAmount as number,
        unit: l.unit,
        lineItemShortId: shortIdGenerator(),
        taxable: l.isTaxable,
        alternativeGlCodes: [
          ...glCodesResponse.data.glCodes.map((glCode: TGlCode) => ({
            glCode: glCode,
            colorCode: "grey",
            confidenceScore: 0
          }))
        ] as TAlternativeGlCode[],
        isTaxable: l.isTaxable,
        gl: l.glCode as TGlCode,
        total: invoice.totalAmount
      };
    });
    setInvoiceTotal(invoice.totalAmount);
    setTotalTaxAmount(invoice.totalTaxAmount);
    setLastIndex(lastIndex);
    setLineItems(lineItems);
    setLoading(false);
    if (invoice?.isLienWaiverRequired && !invoice?.legalFormId) {
      handleSignDocumentModalOpen();
    }
  };

  useEffect(() => {
    fetchInvoice();
    // eslint-disable-next-line
  }, []);

  const { data } = useFetchOriginalInvoiceUrl(invoiceInfo?.persistedInvoiceFileId as string);
  const originalInvoiceUrl = data?.data?.downloadUrl;
  const { data: legalFormUrl } = useFetchOriginalInvoiceUrl(invoice?.legalFormId as string);

  const onIssuingCompanyInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const invoiceInfoCopy: InvoiceProps = { ...invoiceInfo };
    invoiceInfoCopy[e.target.name as keyof InvoiceProps] = e.target.value;
    setInvoiceInfo(invoiceInfoCopy);
  };

  const onIssueDateChange: DatePickerProps["onChange"] = (_date, dateString) => {
    const invoiceInfoCopy: InvoiceProps = { ...invoiceInfo };
    invoiceInfoCopy["issueDate"] = dateString;
    setInvoiceInfo(invoiceInfoCopy);
  };

  const handleEditLineItem = (
    lineItemId: string,
    lineItemChangeKey: string,
    lineItemValue: unknown,
    unitAllToAll = false
  ) => {
    if (lineItemChangeKey === "pricePerUnit" || lineItemChangeKey === "postTaxAmount") {
      if (isNaN(Number(lineItemValue))) {
        return;
      }
    }
    let copiedLineItems;
    if (unitAllToAll) {
      copiedLineItems = lineItems.map((lineItem) => {
        return getUpdatedLineItemsOnEditing(
          lineItem.lineItemShortId,
          lineItemChangeKey,
          lineItemValue,
          lineItems,
          totalTaxAmount,
          units,
          invoice?.lineItems
        ).filter((lineItemFil) => lineItemFil.lineItemShortId === lineItem.lineItemShortId)[0];
      });
    } else {
      copiedLineItems = getUpdatedLineItemsOnEditing(
        lineItemId,
        lineItemChangeKey,
        lineItemValue,
        lineItems,
        totalTaxAmount,
        units,
        invoice?.lineItems
      );
    }

    setChangedValues(
      copiedLineItems
        .map((lineItemValue) => lineItemValue?.esignRequired)
        .filter((l) => l && Object.keys(l)?.length > 0)
    );
    setInvoiceTotal(getInvoiceTotal(copiedLineItems));
    setLineItems(copiedLineItems);
  };

  const handleLineItemDelete = (key: number) => {
    const filteredLineItems: ILineItem[] = lineItems.filter((l) => l.key !== key);
    setInvoiceTotal(getInvoiceTotal(filteredLineItems));
    setLineItems(filteredLineItems);
  };

  const addItem = (newItem: ILineItem) => {
    const index = lastIndex + 1;
    newItem.key = index;
    newItem.lineItemShortId = shortIdGenerator();
    newItem.approvalStatus = INVOICE_PENDING;
    newItem.rejectionReason = "";
    newItem.amount = 0;
    newItem.unit = units.filter((u: TUnit) => u.id === newItem.unitId)[0];
    newItem.gl = glCodes.filter((glCode: TGlCode) => glCode.id === Number(newItem.gl))[0];
    newItem.esignRequired = true;
    (newItem.alternativeGlCodes = [
      ...glCodes.map((glCode: TGlCode) => ({
        glCode: glCode,
        colorCode: "grey",
        confidenceScore: 0
      }))
    ] as TAlternativeGlCode[]),
      (newItem.postTaxAmount = Number(newItem.pricePerUnit));
    newItem.pricePerUnit = Number(newItem.pricePerUnit);
    newItem.preTaxAmount = Number(newItem.pricePerUnit);
    const copiedLineItems: ILineItem[] = [...lineItems];
    copiedLineItems.push(newItem);
    setLastIndex(index);
    revisePostTaxAmount(lineItems);
    setInvoiceTotal(getInvoiceTotal(copiedLineItems));
    setLineItems(copiedLineItems);
    setDisplayAddItemModal(false);
  };

  const submitInvoiceForm = async () => {
    try {
      if (!invoiceInfo.issueDate) {
        ShowToast("Issue date can't be blank", "error");
        return;
      }
      const serializedInvoice: IInvoiceSerialized = transformInvoiceForSubmission(
        invoice as IInvoice,
        invoiceInfo,
        invoiceTotal,
        totalTaxAmount,
        lineItems,
        property
      );

      if (submissionId && !invoice?.legalFormId) {
        serializedInvoice.legalFormId = submissionId?.toString();
        serializedInvoice.isLienWaiverRequired = true;
      }

      setLoading(true);
      await invoiceUpdate(serializedInvoice);
      navigate("/");
      ShowToast("Invoice Submit successfully", "success");
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ShowToast(error.response?.data.message, "error");
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isFormSigned) {
      submitInvoiceForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormSigned]);

  useEffect(() => {
    if (checkboxSigned && !changedValueModalOpen) {
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkboxSigned, changedValueModalOpen]);

  const handleSubmit = async () => {
    const taxableLineItemsCount = lineItems.filter((l) => l.taxable).length;

    if (totalTaxAmount > 0 && taxableLineItemsCount === 0) {
      ShowToast(
        "Either total tax amount should be 0 or atleast one line item should be taxable",
        "error"
      );
      return;
    }
    if (!invoiceInfo.invoiceNumber) {
      ShowToast("Invoice no can't be blank", "error");
      return;
    }
    if (!invoiceInfo.issueDate) {
      ShowToast("Issue date can't be blank", "error");
      return;
    }
    if (!invoiceInfo.address) {
      ShowToast("Address can't be blank", "error");
      return;
    }
    for (const lineItem of lineItems) {
      if (!lineItem.description) {
        ShowToast("Line Item description can't be blank", "error");
        return;
      }
      if (!lineItem.preTaxAmount) {
        ShowToast("Line Item pre tax amount value can't be zero", "error");
        return;
      }
    }

    if (changedValues.length > 0 && !checkboxSigned) {
      setChangedValueModalOpen(true);
      return;
    }

    if (threshold && invoiceTotal >= Number(threshold) && !isFormSigned) {
      handleSignDocumentModalOpen();
    } else {
      if (invoice?.isLienWaiverRequired && !invoice?.legalFormId) {
        handleSignDocumentModalOpen();
      } else {
        submitInvoiceForm();
      }
    }
  };

  const handleDisplayAddItemModal = () => {
    formRef.current?.setFieldsValue({
      description: null,
      amount: Number(0).toFixed(2),
      glCode: null,
      pricePerUnit: Number(0).toFixed(2)
    });
    setDisplayAddItemModal(true);
  };

  const navigateToUploadInvoicePage = () => {
    navigate("/");
  };

  const onTaxAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const decimalRegex = /^-?\d*\.?\d*$/;
    const numValidator = new RegExp(decimalRegex);
    const isValidNumber = numValidator.test(e.target.value);
    if (isValidNumber) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setTotalTaxAmount(e.target.value as any);
    }
  };

  const revisePostTaxAmount = (lineItems: ILineItem[]) => {
    lineItems.map((l) => {
      handleEditLineItem(l.lineItemShortId, "taxable", l.taxable);
    });
  };

  useEffect(() => {
    const taxableLineItemsCount = lineItems.filter((l) => l.taxable).length;
    if (taxableLineItemsCount > 0) {
      revisePostTaxAmount(lineItems);
    } else {
      setInvoiceTotal(getInvoiceTotal(lineItems) + Number(totalTaxAmount));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalTaxAmount]);
  const onSelectedRowTaxableChange = (checked: boolean) => {
    setSelectedRowTaxable(checked);
  };

  useEffect(() => {
    updateDefaultValues({ invoiceInfo, invoiceTotal, vendorName, vendorEmail });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceInfo, invoiceTotal, vendorName, vendorEmail]);

  return (
    <>
      <ReviewInvoice
        invoiceId={invoiceId}
        invoiceInfo={invoiceInfo}
        displayIssuingCompanyForm={displayIssuingCompanyForm}
        setDisplayIssuingCompanyForm={setDisplayIssuingCompanyForm}
        onIssuingCompanyInfoChange={onIssuingCompanyInfoChange}
        displayIssueDateInput={displayIssueDateInput}
        setDisplayIssueDateInput={setDisplayIssueDateInput}
        onIssueDateChange={onIssueDateChange}
        lineItems={lineItems}
        handleLineItemDelete={handleLineItemDelete}
        displayAddItemModal={displayAddItemModal}
        setDisplayAddItemModal={setDisplayAddItemModal}
        addItem={addItem}
        glCodes={glCodes}
        invoiceTotal={invoiceTotal}
        loading={loading}
        handleSubmit={handleSubmit}
        navigateToUploadInvoicePage={navigateToUploadInvoicePage}
        formRef={formRef}
        handleDisplayAddItemModal={handleDisplayAddItemModal}
        editGLCodes={editGLCodes}
        totalTaxAmount={totalTaxAmount}
        onTaxAmountChange={onTaxAmountChange}
        originalInvoiceUrl={originalInvoiceUrl}
        selectedRowTaxable={selectedRowTaxable}
        onSelectedRowTaxableChange={onSelectedRowTaxableChange}
        handleEditLineItem={handleEditLineItem}
        rejectedInvoiceView={true}
        invoiceApprovalStatus={INVOICE_REJECTED}
        invoiceRejectionReason={invoice?.rejectionReason}
        units={units}
        propertyType={invoiceInfo.propertyType as string}
        legalFormUrl={legalFormUrl?.data?.downloadUrl}
        userLogsView={false}
      />
      <SignDocumentModal
        openSignDocumentModal={openSignDocumentModal}
        handleSignDocumentModalClose={handleSignDocumentModalClose}
        defaultValues={defaultValues}
        handleFormSignSuccess={handleFormSignSuccess}
      />
      <LineItemChangeModal
        setChangeModalOpen={setChangedValueModalOpen}
        isModalOpen={changedValueModalOpen}
        setCheckboxSigned={setCheckboxSigned}
      />
    </>
  );
};
export default VendorViewRejectedInvoicePage;
