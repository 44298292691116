import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import VendorDashboard from "../../../Component/Vendor/VendorDashboard";
import { useDispatch, useSelector } from "react-redux";
import { IInvoiceListState } from "../../../redux/reducers/Invoice/InvoiceList";
import { AppDispatch, IAppState } from "../../../redux/reducers/rootReducer";
import {
  loadAllInvoices,
  invoiceListWithInitialFilters,
  setApprovalStatus,
  setCurrentPage,
  setPaymentStatus
} from "../../../redux/actions/Invoice/InvoiceList";
import { PaginationProps } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import moment from "moment";
import { INVOICE_REJECTED } from "../../../utils/constants";
import { IAuthState } from "../../../redux/reducers/Auth/Auth";
import ShowToast from "@utils/showToast";
import { invoiceCancel } from "../../../Services/InvoiceService";
import { isAxiosError } from "axios";

interface IInvoice {
  Id: string;
  invoiceNumber: string;
  companyName: string;
  address: string;
  amount: number;
  paymentStatus: string;
  approvalStatus: string;
  propertyName: string;
  propertyType: string;
  issueDate: string | null;
  lineItems: Array<{
    description: string;
    pricePerUnit: number;
    gl: string;
    unit?: { id: number; name: string };
  }>;
  propertyAddress: string;
  totalTaxAmount: number;
  persistedInvoiceFileId: string;
  submitted: string;
  vendorName: string;
  property?: unknown;
}

const VendorDashboardPage: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const loginState: IAuthState = useSelector((state: IAppState) => state.auth);
  const [invoiceList, setInvoiceList] = useState<Array<IInvoice>>([]);
  const invoiceListState: IInvoiceListState = useSelector((state: IAppState) => state.invoiceList);
  const { pageNum, pageSize, invoices, totalPages, isLoading, paymentStatus, approvalStatus } =
    invoiceListState;

  const { loginInfo } = loginState;

  const showPaymentStatus: boolean = loginInfo?.showPaymentStatus
    ? loginInfo?.showPaymentStatus
    : false;

  const name = loginInfo?.name;
  const threshold = loginInfo?.threshold;
  const isActive = loginInfo?.isActive;

  useEffect(() => {
    // eslint-disable-next-line
    const list = [...invoices].map((i: any) => {
      const sum = i.totalAmount;
      const totalTaxAmount = i.totalTaxAmount;

      return {
        Id: i.Id,
        invoiceNumber: i.invoiceId,
        companyName: i.payee.name,
        address: i.payee.address,
        propertyAddress: i.propertyAddresses[0].Address,
        rmPropertyAddress: i?.rmPropertyAddresses[0]?.Address,
        approvalStatus: i.approvalStatus,
        paymentStatus: i.paymentStatus,
        amount: sum,
        propertyName: i.property.name,
        propertyType: i.propertyType,
        property: i.property,
        issueDate: i.issueDate ? moment(i.issueDate).format("YYYY-MM-DD") : null,
        lineItems: i.lineItems,
        totalTaxAmount,
        persistedInvoiceFileId: i.persistedInvoiceFileId,
        submitted: moment(i.createdAt).format("YYYY-MM-DD"),
        vendorName: name
      } as IInvoice;
    });
    setInvoiceList(list);
  }, [invoices]);

  const navigateToUploadInvoice = () => {
    navigate("/vendorUploadInvoice");
  };

  const allVendorInvoices = () => {
    dispatch(
      loadAllInvoices(pageNum, pageSize, paymentStatus.toString(), approvalStatus.toString())
    );
  };

  useEffect(() => {
    allVendorInvoices();
    // eslint-disable-next-line
  }, [pageNum, pageSize, paymentStatus, approvalStatus]);

  const onPaginationChange: PaginationProps["onChange"] = (page, size) => {
    dispatch(setCurrentPage(page, size));
  };

  const navigateToViewInvoice = (invoiceId: string, approvalStatus: string) => {
    if (approvalStatus === INVOICE_REJECTED) {
      navigate(`/vendorViewRejectedInvoice/${invoiceId}`);
    } else {
      navigate(`/vendorViewInvoice/${invoiceId}`);
    }
  };

  const handleCancelInvoice = async (invoiceId: string) => {
    try {
      await invoiceCancel(invoiceId);
      navigate("/");
      ShowToast("Invoice Cancelled successfully", "success");
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ShowToast(error.response?.data.message, "error");
      }
    }
  };

  const onPaymentStatusChange = (e: CheckboxChangeEvent) => {
    dispatch(setPaymentStatus(getFilterValue(e, paymentStatus)));
  };

  const onApprovalStatusChange = (e: CheckboxChangeEvent) => {
    dispatch(setApprovalStatus(getFilterValue(e, approvalStatus)));
  };

  const getFilterValue = (e: CheckboxChangeEvent, filterArray: any) => {
    if (e.target.checked) {
      return [...filterArray, e.target.name];
    }
    return filterArray.filter((filter: any) => filter != e.target.name);
  };

  const clearFilters = () => {
    dispatch(invoiceListWithInitialFilters());
  };

  return (
    <VendorDashboard
      isManagerPortal={false}
      navigateToUploadInvoice={navigateToUploadInvoice}
      pageNum={pageNum}
      pageSize={pageSize}
      totalPages={totalPages}
      invoiceList={invoiceList}
      onPaginationChange={onPaginationChange}
      isLoading={isLoading}
      navigateToViewInvoice={navigateToViewInvoice}
      handleCancelInvoice={handleCancelInvoice}
      paymentStatus={paymentStatus}
      approvalStatus={approvalStatus}
      onPaymentStatusChange={onPaymentStatusChange}
      onApprovalStatusChange={onApprovalStatusChange}
      clearFilters={clearFilters}
      showPaymentStatus={showPaymentStatus}
      title={`Vendor Portal (${name})`}
      threshold={threshold}
      isActive={isActive}
    />
  );
};
export default VendorDashboardPage;
