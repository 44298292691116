/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useSelector } from "react-redux";
import { FC, useEffect, useRef, useState } from "react";
import { DatePickerProps } from "antd";
import { useNavigate } from "react-router-dom";
import ReviewInvoice from "@Component/Common/ReviewInvoice";
import { IInvoiceState } from "@redux/reducers/Invoice/Invoice";
import { IAppState } from "@redux/reducers/rootReducer";
import moment from "moment";
import { submitInvoice } from "@Services/InvoiceService";
import ShowToast from "@utils/showToast";
import { IAuthState } from "@redux/reducers/Auth/Auth";
import { isAxiosError } from "axios";
import useFetchOriginalInvoiceUrl from "@hooks/common/useFetchOriginalInvoiceUrl";
import { shortIdGenerator } from "@utils/shortIdGenerator";
import { getInvoiceTotal, getUpdatedLineItemsOnEditing } from "./utils";
import SignDocumentModal from "@Component/Common/ReviewInvoice/SignDocumentModal";
import useSignDocumentState from "@Component/Vendor/Common/useSignDocumentState";
import {
  ILineItem,
  InvoiceProps,
  TAlternativeGlCode,
  TGlCode,
  TProperties,
  TUnit
} from "@Component/Common/types";

const VendorReviewInvoicePage: FC = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>();
  const scannedInvoiceState: IInvoiceState = useSelector((state: IAppState) => state.invoice);
  const [invoiceInfo, setInvoiceInfo] = useState<InvoiceProps>({
    invoiceId: "",
    companyName: "",
    propertyName: "",
    address: "",
    invoiceNumber: "",
    issueDate: "",
    totalAmount: 0,
    totalTaxAmount: 0
  });
  const loginState: IAuthState = useSelector((state: IAppState) => state.auth);
  const [displayIssuingCompanyForm, setDisplayIssuingCompanyForm] = useState<boolean>(false);
  const [displayIssueDateInput, setDisplayIssueDateInput] = useState<boolean>(false);
  const [lineItems, setLineItems] = useState<ILineItem[]>([]);
  const [lastIndex, setLastIndex] = useState<number>(0);
  const [displayAddItemModal, setDisplayAddItemModal] = useState<boolean>(false);
  const [glCodes, setGlCodes] = useState<TGlCode[]>([]);
  const [invoiceTotal, setInvoiceTotal] = useState<number>(0);
  const [totalTaxAmount, setTotalTaxAmount] = useState<number>(0);
  const [units, setUnits] = useState<TUnit[]>([]);
  const [property, setProperty] = useState<TProperties[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRowTaxable, setSelectedRowTaxable] = useState<boolean>(false);

  const {
    defaultValues,
    updateDefaultValues,
    openSignDocumentModal,
    handleSignDocumentModalClose,
    isFormSigned,
    handleSignDocumentModalOpen,
    handleFormSignSuccess,
    submissionId,
    setIsFormSigned
  } = useSignDocumentState();
  const { scannedInvoice } = scannedInvoiceState;
  const persistedInvoiceFileId = scannedInvoice.persistedInvoiceFileId;
  const Id = scannedInvoice.Id
  const { loginInfo } = loginState;

  const editGLCodes: boolean = loginInfo?.editGLCodes ? loginInfo?.editGLCodes : false;
  const threshold = loginInfo?.threshold;
  const vendorName = loginInfo?.name;
  const vendorEmail = loginInfo?.email;

  useEffect(() => {
    const invoice = scannedInvoice.invoice;
    const address = scannedInvoice.vendorAddresses[0].Address?.replace("\r\n", " ");
    const propertyAddress = scannedInvoice.propertyAddresses[0].Address?.replace("\r\n", " ");
    setProperty(invoice.property);
    if (invoice.property.unit) {
      const unitsArray = invoice.property.unit.filter((unit: TUnit) => unit.id != 0);
      invoice.property.unit = [{ id: 0, name: "None" }, ...unitsArray];
      setUnits(invoice.property.unit);
    }
    let lastIndex = 0;

    setInvoiceInfo({
      invoiceId: invoice?.Id,
      companyName: invoice?.payee.name,
      propertyName: invoice?.property.name,
      propertyAddress,
      vendorName,
      address,
      invoiceNumber: invoice.invoiceId,
      totalAmount: invoice.totalAmount,
      totalTaxAmount: invoice.totalTaxAmount - invoice.totalTaxAmount,
      issueDate: invoice.issueDate ? moment(invoice.issueDate).format("YYYY-MM-DD") : null
    });
    setTotalTaxAmount(invoice.totalTaxAmount);
    const glCodes = (invoice?.lineItems?.[0]?.alternativeGlCodes.map(
      (alGCode: TAlternativeGlCode) => alGCode.glCode
    ) || []) as TGlCode[];
    setGlCodes(glCodes);
    const lineItems = invoice.lineItems.map(
      (
        l: {
          amount: number;
          preTaxAmount: number;
          postTaxAmount: number;
          description: string;
          glCode: TGlCode;
          taxable: boolean;
          lineItemShortId: string;
          unit: TUnit;
          alternativeGlCodes: TAlternativeGlCode[];
        },
        index: number
      ) => {
        lastIndex = index;
        return {
          key: index,
          description: l.description,
          pricePerUnit: l.amount,
          preTaxAmount: Number(l.amount).toFixed(2),
          postTaxAmount: l.amount,
          gl: l.glCode,
          total: l.amount,
          taxable: l.taxable || false,
          lineItemShortId: shortIdGenerator(),
          unit: l.unit,
          alternativeGlCodes: l.alternativeGlCodes
        };
      }
    );
    setInvoiceTotal(getInvoiceTotal(lineItems));
    setTotalTaxAmount(invoice.totalTaxAmount);
    setLastIndex(lastIndex);
    setLineItems(lineItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data } = useFetchOriginalInvoiceUrl(persistedInvoiceFileId);
  const originalInvoiceUrl = data?.data?.downloadUrl;

  const onIssuingCompanyInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const invoiceInfoCopy: InvoiceProps = { ...invoiceInfo };
    invoiceInfoCopy[e.target.name as keyof InvoiceProps] = e.target.value;
    setInvoiceInfo(invoiceInfoCopy);
  };

  const onIssueDateChange: DatePickerProps["onChange"] = (_date, dateString) => {
    const invoiceInfoCopy: InvoiceProps = { ...invoiceInfo };
    invoiceInfoCopy["issueDate"] = dateString;
    setInvoiceInfo(invoiceInfoCopy);
  };
  const onSelectedRowTaxableChange = (checked: boolean) => {
    setSelectedRowTaxable(checked);
  };

  const handleLineItemDelete = (key: number) => {
    const filteredLineItems = lineItems.filter((l) => l.key !== key);
    setInvoiceTotal(getInvoiceTotal(filteredLineItems));
    setLineItems(filteredLineItems);
  };

  const revisePostTaxAmount = (lineItems: Array<ILineItem>) => {
    lineItems.map((l) => {
      handleEditLineItem(l.lineItemShortId, "taxable", l.taxable);
    });
  };

  const addItem = (newItem: ILineItem) => {
    const propertyType = scannedInvoice?.propertyType?.replace(/\s/g, "");
    const index = lastIndex + 1;
    newItem.key = index;
    newItem.lineItemShortId = shortIdGenerator();
    newItem.pricePerUnit = Number(newItem.pricePerUnit);
    newItem.preTaxAmount = Number(newItem.pricePerUnit);
    newItem.postTaxAmount = Number(newItem.pricePerUnit);
    newItem.gl = glCodes.filter((glCode: TGlCode) => glCode.id === Number(newItem.gl))[0];
    if (propertyType === "UnitProperty") {
      const filteredUnits = units.filter((u: TUnit) => u.id === newItem.unitId);
      newItem.unit = filteredUnits[0];
    }
    const copiedLineItems: Array<ILineItem> = [...lineItems];
    copiedLineItems.push(newItem as ILineItem);
    setLastIndex(index);
    revisePostTaxAmount(lineItems);
    setInvoiceTotal(getInvoiceTotal(copiedLineItems));
    setLineItems(copiedLineItems);
    setDisplayAddItemModal(false);
  };

  const handleEditLineItem = (
    lineItemId: string,
    lineItemChangeKey: string,
    lineItemValue: unknown,
    unitAllToAll = false
  ) => {
    if (lineItemChangeKey === "pricePerUnit" || lineItemChangeKey === "postTaxAmount") {
      if (isNaN(Number(lineItemValue?.toString().replace(".", "")))) {
        return;
      }
    }

    let copiedLineItems;
    if (unitAllToAll) {
      copiedLineItems = lineItems.map((lineItem) => {
        return getUpdatedLineItemsOnEditing(
          lineItem.lineItemShortId,
          lineItemChangeKey,
          lineItemValue,
          lineItems,
          totalTaxAmount,
          units
        ).filter((lineItemFil) => lineItemFil.lineItemShortId === lineItem.lineItemShortId)[0];
      });
    } else {
      copiedLineItems = getUpdatedLineItemsOnEditing(
        lineItemId,
        lineItemChangeKey,
        lineItemValue,
        lineItems,
        totalTaxAmount,
        units
      );
    }
    setInvoiceTotal(getInvoiceTotal(copiedLineItems));
    setLineItems(copiedLineItems);
  };

  const submitInvoiceForm = async () => {
    try {
      const propertyType = scannedInvoice?.propertyType?.replace(/\s/g, "");
      const propertyAddresses = scannedInvoice?.propertyAddresses;
      const vendorAddresses = scannedInvoice?.vendorAddresses;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const invoiceData: any = {
        invoiceId: invoiceInfo.invoiceNumber,
        issueDate: invoiceInfo.issueDate,
        payee: {
          name: invoiceInfo.companyName ? invoiceInfo.companyName : "",
          address: invoiceInfo.address
        },
        property: property,
        propertyAddresses,
        vendorAddresses,
        totalAmount: Number(invoiceTotal),
        totalTaxAmount: Number(totalTaxAmount),
        propertyType: propertyType,
        cardDetails: [],
        isHomeDepot: false,
        isSubmittable: true,
        persistedInvoiceFileId: persistedInvoiceFileId,
        Id: Id,
        legalFormId: submissionId?.toString()
      };
      invoiceData["lineItems"] = lineItems.map((l) => {
        return {
          glCode: { ...l.gl, reference: l.gl.reference.toString() },
          description: l.description,
          amount: l.postTaxAmount ? Number(l.postTaxAmount) : 0,
          postTaxAmount: l.postTaxAmount ? Number(l.postTaxAmount) : 0,
          preTaxAmount: l.preTaxAmount ? Number(l.preTaxAmount) : 0,
          unit: l.unit,
          isTaxable: l.taxable
        };
      });
      invoiceData["internalLineItems"] = invoiceData["lineItems"];
      setLoading(true);
      await submitInvoice(invoiceData);
      setIsFormSigned(false);
      window.location.href = "/";
      ShowToast("Invoice Submit successfully", "success");
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ShowToast(error.response?.data.message, "error");
      }
      setLoading(false);
    }
  };
  useEffect(() => {
    if (isFormSigned) {
      submitInvoiceForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormSigned]);
  const handleSubmit = async () => {
    const taxableLineItemsCount = lineItems.filter((l) => l.taxable).length;
    if (totalTaxAmount > 0 && taxableLineItemsCount === 0) {
      ShowToast(
        "Either total tax amount should be 0 or atleast one line item should be taxable",
        "error"
      );
      return;
    }
    if (!invoiceInfo.invoiceNumber) {
      ShowToast("Invoice no can't be blank", "error");
      return;
    }
    if (!invoiceInfo.issueDate) {
      ShowToast("Issue date can't be blank", "error");
      return;
    }
    if (!invoiceInfo.address) {
      ShowToast("Address can't be blank", "error");
      return;
    }
    for (const lineItem of lineItems) {
      if (!lineItem.description) {
        ShowToast("Line Item description can't be blank", "error");
        return;
      }

      if (!Number(lineItem.preTaxAmount)) {
        ShowToast("Line Item pre tax amount value can't be zero", "error");
        return;
      }
    }
    if (threshold && invoiceTotal >= Number(threshold) && !isFormSigned) {
      handleSignDocumentModalOpen();
    } else {
      submitInvoiceForm();
    }
  };

  const handleDisplayAddItemModal = () => {
    formRef.current?.setFieldsValue({
      description: null,
      pricePerUnit: null,
      gl: null,
      unitId: null
    });
    setDisplayAddItemModal(true);
  };

  const navigateToUploadInvoicePage = () => {
    navigate("/");
  };

  const onTaxAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const decimalRegex = /^-?\d*\.?\d*$/;
    const numValidator = new RegExp(decimalRegex);
    const isValidNumber = numValidator.test(e.target.value);
    if (isValidNumber) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setTotalTaxAmount(e.target.value as any);
    }
  };

  useEffect(() => {
    const taxableLineItemsCount = lineItems.filter((l) => l.taxable).length;
    if (taxableLineItemsCount > 0) {
      revisePostTaxAmount(lineItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalTaxAmount]);

  useEffect(() => {
    updateDefaultValues({ invoiceInfo, invoiceTotal, vendorName, vendorEmail });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceInfo, invoiceTotal, vendorName, vendorEmail]);

  return (
    <>
      <ReviewInvoice
        invoiceInfo={invoiceInfo}
        displayIssuingCompanyForm={displayIssuingCompanyForm}
        setDisplayIssuingCompanyForm={setDisplayIssuingCompanyForm}
        onIssuingCompanyInfoChange={onIssuingCompanyInfoChange}
        displayIssueDateInput={displayIssueDateInput}
        setDisplayIssueDateInput={setDisplayIssueDateInput}
        onIssueDateChange={onIssueDateChange}
        lineItems={lineItems}
        handleLineItemDelete={handleLineItemDelete}
        displayAddItemModal={displayAddItemModal}
        setDisplayAddItemModal={setDisplayAddItemModal}
        addItem={addItem}
        glCodes={glCodes}
        invoiceTotal={invoiceTotal}
        loading={loading}
        handleSubmit={handleSubmit}
        navigateToUploadInvoicePage={navigateToUploadInvoicePage}
        formRef={formRef}
        handleDisplayAddItemModal={handleDisplayAddItemModal}
        editGLCodes={editGLCodes}
        totalTaxAmount={totalTaxAmount}
        onTaxAmountChange={onTaxAmountChange}
        originalInvoiceUrl={originalInvoiceUrl}
        selectedRowTaxable={selectedRowTaxable}
        onSelectedRowTaxableChange={onSelectedRowTaxableChange}
        handleEditLineItem={handleEditLineItem}
        units={units}
        propertyType={scannedInvoice?.propertyType?.replace(/\s/g, "")}
        userLogsView={false}
      />
      <SignDocumentModal
        openSignDocumentModal={openSignDocumentModal}
        handleSignDocumentModalClose={handleSignDocumentModalClose}
        defaultValues={defaultValues}
        handleFormSignSuccess={handleFormSignSuccess}
      />
    </>
  );
};
export default VendorReviewInvoicePage;
